import { render, staticRenderFns } from "./cookies_component.vue?vue&type=template&id=68760cae&scoped=true&"
import script from "./cookies_component.vue?vue&type=script&lang=js&"
export * from "./cookies_component.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68760cae",
  null
  
)

export default component.exports