<template>
  <div class="terms">
    <terms_conditions_component/>
  </div>
</template>

<script>
// @ is an alias to /src
import terms_conditions_component from '@/components/terms_conditions_component.vue'

export default {
  name: 'home',
  components: {
    terms_conditions_component
  }
}
</script>
