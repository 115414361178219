<template>
  <div class="online-shop">
    <online_shop_component/>
  </div>
</template>

<script>
// @ is an alias to /src
import online_shop_component from '@/components/online_shop_component.vue'

export default {
  name: 'online_shop',
  components: {
    online_shop_component
  }
}
</script>
