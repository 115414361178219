<template>
  <div class="contact">
    <contact_component/>
  </div>
</template>

<script>
// @ is an alias to /src
import contact_component from '@/components/contact_component.vue'

export default {
  name: 'contact',
  components: {
    contact_component
  }
}
</script>
