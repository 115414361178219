<template>
  <div class="hosting">
    <hosting_component/>
  </div>
</template>

<script>
// @ is an alias to /src
import hosting_component from '@/components/hosting_component.vue'

export default {
  name: 'hosting',
  components: {
    hosting_component
  }
}
</script>
