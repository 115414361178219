<template>
  <div id="contact-background">
    <b-container fluid id="nav">
      <b-navbar toggleable="lg" id="navbar">
        <b-navbar-brand href="/" id="navbar-brand"><img id="oncloud-logo" alt="oncloud-logo"  src="../assets/logo/Asset 3@150x@3x.png"/></b-navbar-brand>
        <b-navbar-toggle target="nav-collapse" id="navbar-toggler">
          <template #default="{ expanded }">
            <div v-if="expanded" icon="chevron-bar-up"><img  alt=""  src="../assets/group1838.png"/></div>
            <div v-else icon="chevron-bar-down"><img  alt=""  src="../assets/group1838.png"/></div>
          </template>
        </b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items with ms auto not ml auto -->
          <b-navbar-nav class="ms-auto">
            <b-nav-item class="nav-item"><router-link to="/">Home</router-link></b-nav-item>
            <b-nav-item-dropdown text="About" right class="nav-item">
              <b-dropdown-item href="cloudflare-pages" class="nav-item"><router-link to="/cloudflare-pages">Cloudflare Pages</router-link></b-dropdown-item>
              <b-dropdown-item href="virtualization" class="nav-item"> <router-link to="/virtualization">Virtualization</router-link></b-dropdown-item>
              <b-dropdown-item href="hosting" class="nav-item"><router-link to="/hosting">Hosting</router-link></b-dropdown-item>
              <b-dropdown-item href="online-shop" class="nav-item"><router-link to="/online-shop">Online shop</router-link></b-dropdown-item>
              <b-dropdown-item href="server-administration" class="nav-item"><router-link to="/server-administration">Server administration</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item><router-link to="/services" class="nav-item">Services</router-link></b-nav-item>
            <b-nav-item><router-link to="/contact" class="nav-item">Contact</router-link></b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-container>
    <b-container class="text-center" style="max-width: 70%" id="contact-container">
      <b-row>
        <b-col sm="12">
          <h1 class="contact-h">Contact us</h1>
          <h3 class="contact-h">Ask anything..right now<br>
            To serious questions, and especially to funny ones, we answer within an hour.</h3>

          <b-form ref="form" @submit.prevent="sendEmail" id="contact-form">
            <b-form-group>
              <label class="contact-labels">Name</label>
              <input
                  type="text"
                  v-model="name"
                  name="name"
                  placeholder="Your Name"
              >
              <small id="emailHelp" class="form-text">We treat all of your personal data as strictly confidential.</small>
            </b-form-group>
            <b-form-group>
              <label class="contact-labels">Email</label>
              <input
                  type="email"
                  v-model="email"
                  name="email"
                  placeholder="Your Email"
              >
              <small id="emailHelp" class="form-text">We will never share your email address with anyone.</small>
            </b-form-group>
            <b-form-group>
              <label class="contact-labels">Message</label>
              <textarea
                  name="message"
                  v-model="message"
                  cols="30" rows="5"
                  placeholder="Message">
          </textarea>
            </b-form-group>
            <div  id="recaptcha-main" class="g-recaptcha" data-sitekey="6Lc0EKAdAAAAAFeANVoYI-s5EOIRH96yYF-lnFRK"></div>
            <br/>
            <b-button class="alert-primary" type="submit" value="Send"> Send <img src="../assets/contact/arrowhead@3x.png" alt="arrow" width="30" height="30"></b-button>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script src="https://www.google.com/recaptcha/api.js?render=6Lc0EKAdAAAAAFeANVoYI-s5EOIRH96yYF-lnFRK"></script>
<script>
import emailjs  from 'emailjs-com';


export default {
  name: 'contact_component',

  data() {
    setTimeout(() => {
      grecaptcha.render('recaptcha-main'); // 'recaptcha-main' is the id that was assigned to the widget
    }, 1000);
    return {
      name: '',
      email: '',
      message: ''
    }
  },
  methods: {
    async sendEmail(e) {
      e.preventDefault();
      try {
        await emailjs.sendForm('service_5s405v3', 'template_6u0japh', this.$refs.form,
            'user_m5KKpzwBi1Hym1nTIfqMZ', {
              name: this.name,
              email: this.email,
              message: this.message
            }

        )

      } catch(error) {
        if(error) {
          await  this.$router.push("/error");
          return
        }
      }
      // Reset form field
      this.name = ''
      this.email = ''
      this.message = ''
      await this.$router.push("/success");
    },
  }
};
</script>

<style scoped>

#nav{
  background-color: #20A975;
}

#oncloud-logo {
  max-width: 325px;
  max-height: 80px;
}

#contact-background {
  background: url("../assets/contact/contactbackground@3x.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 110vh; /*if not set above 100vh it gaps on the big screens on the left side*/
}

.contact-h {
  color: #cccccc;
  font-family: "SegoeUI", sans-serif;
  margin-top: 3%;
}
#contact-form {
  margin-top: 5%;
}

.contact-labels {
  color: white;
  margin-top: 2%;
  font-size: 25px;
}

input[type=text], [type=email], textarea {
  outline: none;
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

.alert-primary {
  font-family: "SegoeUI", sans-serif;
  font-size: 20pt;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  line-height: 27pt;
  background-color: rgb(90, 214, 166);
  border-color: rgb(90, 214, 166);
  border-radius: 12px;
  margin-bottom: 5%;
}

@media screen and (max-width: 768px){
  #navbar-toggler{
    border: none;
    outline: none;
    box-shadow: none;
  }
  #oncloud-logo {
    display: none;
  }
  #navbar-brand {
    background: url("../assets/maskGroup70@3x.png");
    background-size: cover;
    background-position: center center;
    width: 90px;
    max-height: 100px;
    height: 70px;
  }
  #contact-container {
       max-width: unset!important;
     }
  .alert-primary {
    padding: 10px 50px;
    font-size: 15pt;
    line-height: 15pt;
  }
}


</style>
