<template>
  <div class="success">
    <success_component/>
  </div>
</template>

<script>
// @ is an alias to /src
import success_component from '@/components/success_component.vue'

export default {
  name: 'home',
  components: {
    success_component
  }
}
</script>
