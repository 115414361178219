<template>
  <div class="cloudflare-pages">
    <b-container fluid id="nav">
      <b-navbar toggleable="lg" id="navbar">
        <b-navbar-brand href="/" id="navbar-brand"><img id="oncloud-logo" alt="oncloud-logo"  src="../assets/logo/Asset 3@150x@3x.png"/></b-navbar-brand>
        <b-navbar-toggle target="nav-collapse" id="navbar-toggler">
          <template #default="{ expanded }">
            <div v-if="expanded" icon="chevron-bar-up"><img  alt=""  src="../assets/group1838.png"/></div>
            <div v-else icon="chevron-bar-down"><img  alt=""  src="../assets/group1838.png"/></div>
          </template>
        </b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items with ms auto not ml auto -->
          <b-navbar-nav class="ms-auto">
            <b-nav-item class="nav-item"><router-link to="/">Home</router-link></b-nav-item>
            <b-nav-item-dropdown text="About" right>
              <b-dropdown-item href="virtualization"> <router-link to="/virtualization">Virtualization</router-link></b-dropdown-item>
              <b-dropdown-item href="hosting"><router-link to="/hosting">Hosting</router-link></b-dropdown-item>
              <b-dropdown-item href="online-shop"><router-link to="/online-shop">Online shop</router-link></b-dropdown-item>
              <b-dropdown-item href="server-administration"><router-link to="/server-administration">Server administration</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item><router-link to="/services" class="nav-item">Services</router-link></b-nav-item>
            <b-nav-item><router-link to="/contact" class="nav-item">Contact</router-link></b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-container>
    <b-container>
      <b-row>
        <b-col sm="6">
          <article>
            <h1 id="what-are-h1">So, what are GitHub, Cloudflare and Oncloud?</h1>
            <h3 class="main-h3"><b>GitHub</b> as it is stated on their website: "Where the world builds software. Millions of developers and companies build, ship, and maintain their software on GitHub—the largest and
              most advanced development platform in the world."
            </h3>
            <h3>What does this mean for our case?</h3>
            <h3> Every website is in fact a software. It needs to be written, compiled and deployed. So, as you will later see, our website needs to stay somewhere in order for
              Cloudflare to be able to fetch it. In our case it will be GitHub. It can host and keep track of every stage of the website development in order for you, the client, to be able to check our
              advancement, and for us, the developers to be able to role back
              in case something goes wrong. More on what is GitHub <a href="https://github.com/git-guides" class="small-tag-a" target="_blank"> HERE.</a>
            </h3>
          </article>
        </b-col>
        <b-col sm="6">
          <img src="../assets/github@3x.jpg" alt="GitHub" class="img-fluid zoom">
          <div class="text-center for-margin-bottom-imgClass">
          <small>Screenshot from <a href="https://github.com/" class="small-tag-a" target="_blank">GitHub.com</a></small>
          </div>
        </b-col>
        <b-col sm="12">
          <article>
            <h3 class="main-h3">What about <b>Cloudflare</b>?</h3>
            <h3>As stated on their website: "A global network built for the cloud. Write and deploy code that runs on the network edge." We must add to this, in order for you to fully understand,
              the word "servers", so a global network of servers build for the cloud.
            </h3>
            <h3>What does this even mean?</h3>
            <h3>It means that Cloudflare will get our software from GitHub, compile it, and deploy it on each and every server on their network. And they are global, meaning that if you are in
              Australia, you will get your website as if it were locally hosted, from one of Cloudflare's servers in Australia. And this gets us to Cloudflare Pages.
            </h3>
          </article>
        </b-col>
        <b-col sm="12">
          <img src="../assets/cloudflare-1.jpg" alt="cloudflare pages" class="img-fluid">
          <div class="text-center for-margin-bottom-imgClass">
          <small>Screenshot from <a href="https://pages.cloudflare.com/" class="small-tag-a" target="_blank">Cloudflare Pages</a></small>
          </div>
        </b-col>
        <b-col sm="12">
          <article>
            <h3 id="cloudflare-advantages-h3">
              By leveraging the power of Cloudflare Pages we have several humongous advantages and let us enumerate just a few:
            </h3>
          </article>
          <b-list-group>
            <b-list-group-item class="cloudflare-advantages-list">Your website will never be offline unless Earth will be under alien attack.</b-list-group-item>
            <b-list-group-item class="cloudflare-advantages-list">It will load fast especially on mobiles with slow internet connection</b-list-group-item>
            <b-list-group-item class="cloudflare-advantages-list"><img src="../assets/scalable-secure.png" class="img-fluid for-margin-bottom-imgClass"></b-list-group-item>
            <div class="text-center for-margin-bottom-imgClass">
            <small>Screenshot from <a href="https://pages.cloudflare.com/" class="small-tag-a text-center" target="_blank">Cloudflare Pages</a></small>
            </div>
            <b-list-group-item class="cloudflare-advantages-list">It loads blazing fast all over the world.</b-list-group-item>
            <b-list-group-item class="cloudflare-advantages-list">No need for dedicated hosting as Cloudflare acts not only as a CDN but also as a host.</b-list-group-item>
            <b-list-group-item class="cloudflare-advantages-list">Top notch security.</b-list-group-item>
            <b-list-group-item class="cloudflare-advantages-list">We develop and you watch online as we progress.</b-list-group-item>
          </b-list-group>
          <h3>More on Clodflare Pages <a href="https://blog.cloudflare.com/cloudflare-pages/" class="small-tag-a" target="_blank">HERE</a></h3>
        </b-col>
        <b-col sm="12" style="margin-top: 5%">
          <article>
            <h3 style="text-indent: 0%!important;">Now let's talk about <b>Oncloud</b> and what we will do for you with the help of the above.</h3>
            <img src="../assets/working_on_oncloud.png" class="img-fluid" style="margin-top: 5%">
            <div class="text-center for-margin-bottom-imgClass">
              <small>Screenshot from <a href="https://oncloud.how/" class="small-tag-a" target="_blank">Oncloud while being developed</a></small>
            </div>
            <h3>The beauty of this type of website development is that is fast. Once you have determined the
            layout of your website with our partner which is also in charge of the design(unless you bring your own design), finished the design with her,
              in max one week the website will be up and running and even more, we will prompt you when to look at the web address
              indicated by us, to see the daily updates.
            </h3>
            <h3>There is no need to look for a hosting company, set up an account, install wordpress, magento or
              whatever. Looking for
              a wordpress developer? Afraid of wordpress errors? History. Nothing traditional. No plugins and no additional costs. Develop and push. That is all. Dead simple.
            </h3>
            <h3>Let's take a break and recap : We write code(screenshot above), put it on GitHub, from there
              Cloudflare grabs it and boom,
            we are live.
            </h3>
            <h3> Getting back to the storyline, as you might have noticed, we are using VueJS as our framework of
              choice
              because javascript is sexy 😀, we consider it better than ReactJs, and because it is one of the very
              few accepted on Cloudflare Pages. With VUE we can build a fully functional presentation website, with or without a blog,
              or a very nice front end for whatever application or interactive website you have in mind.
            </h3>
            <h3>About the blog. How will you publish your stories? Simple. You email us the text and pictures, and if we are not
            sleeping, cut, paste, push it and boom, in 10 minutes your ideas are online. For more advanced users, or for those willing to learn, we can set up a git repository and teach you how to push your own commits.
              Either way your blog will be updated, secured, deployed all over the world and fast. Blazing fast.
            </h3>

            <h3>Now we will talk about the pricing and about the working flow.</h3>
            <h3>First step is to identify exactly what do you need in term of website. Dynamic or presentation, while getting
            to know each other a bit. It is important for both parties to get to know each other in order for the relationship to last.
              After the first 2 or 3 online talks you will get to meet our design partner which will be with you until the website is ready
              to be transformed into code. Then, you will get back to us and fill you in the last details including
              the development site which will be under the form: something.pages.dev.
            </h3>
            <h3>
              About the pricing of coding.<br>
              We want to enable small and medium size businesses to be able to afford a custom website.
              So the pricing starts at 500 eur/website and 50 eur/month after, plus the cost of the design which follows shortly.
              This is the starter package and included here are the following:
            </h3>
            <b-list-group class="text-center">
              <b-list-group-item class="cloudflare-advantages-list">Five webpages including a contact webpage.</b-list-group-item>
              <b-list-group-item class="cloudflare-advantages-list">Up to ten webpages, so 5 more, 50 eur/page. </b-list-group-item>
              <b-list-group-item class="cloudflare-advantages-list">10 revisions included after going live.</b-list-group-item>
              <b-list-group-item class="cloudflare-advantages-list">10 revisions included in the 50 eur/month maintenance fee.</b-list-group-item>
              <b-list-group-item class="cloudflare-advantages-list">Hosting and dns are provided by Cloudflare and are FREE.</b-list-group-item>
            </b-list-group>
            <h3 style="text-indent: 0%!important;">Please keep in mind that subpages/submenus are treated as a standalone webpage, both from our and the designers p-o-w. </h3>
            <h3>About the price of the design.<br>
              The price for one webpage is 100 eur. This consists in design consultancy and maximum 4 online meetings with the client.
              Any other change, after the process is done, will cost up to 50 eur, depending on the complexity. Our partner is
              available for meetings between 10:00 - 19:00 EET. For other time zone we should discuss and adapt.
            </h3>
           <h3>
              When the design is finished it will be passed onto us for coding and deploying.
            </h3>
            <h3>That's all folks! But please, if there is anything else we might have missed or you want to
              learn more about all of this, <a href="#/contact"> contact</a> us.
            </h3>
          </article>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'cloudflare-pages_component'
};
</script>

<style scoped>

#nav{
  background-color: #20A975;
}

#oncloud-logo {
  max-width: 325px;
  max-height: 80px;
}

.zoom {
  margin-top: 20%;
  transition: transform .2s; /* Animation */
}

.zoom:hover {
  transform: scale(1.8); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.for-margin-bottom-imgClass {
  margin-bottom: 5%;
  margin-top: 5%;
}
.small-tag-a {
  text-decoration: none;
}
article {
  font-family: "SegoeUI", sans-serif;
  text-indent: 15%;

}
h3{
  line-height: 1.6!important;
  letter-spacing: 1px;
}
#what-are-h1 {
  margin-top: 5%;
}
.main-h3 {
  margin-top: 10%;
}

#cloudflare-advantages-h3 {
  margin-top: 10%;
}
.cloudflare-advantages-list {
  list-style: none;
  border: none;
  font-size: 25px;

}

@media screen and (max-width: 768px) {
  #navbar-toggler {
    border: none;
    outline: none;
    box-shadow: none;
  }

  #oncloud-logo {
    display: none;
  }

  #navbar-brand {
    background: url("../assets/maskGroup70@3x.png");
    background-size: cover;
    background-position: center center;
    width: 90px;
    max-height: 100px;
    height: 70px;
  }
  .cloudflare-advantages-list {
    list-style: none;
    border: none;
    font-size: unset;

  }
}
</style>
