<template>
  <div class="hosting">
    <b-container fluid id="nav">
      <b-navbar toggleable="lg" id="navbar">
        <b-navbar-brand href="/" id="navbar-brand"><img id="oncloud-logo" alt="oncloud-logo"  src="../assets/logo/Asset 3@150x@3x.png"/></b-navbar-brand>
        <b-navbar-toggle target="nav-collapse" id="navbar-toggler">
          <template #default="{ expanded }">
            <div v-if="expanded" icon="chevron-bar-up"><img  alt=""  src="../assets/group1838.png"/></div>
            <div v-else icon="chevron-bar-down"><img  alt=""  src="../assets/group1838.png"/></div>
          </template>
        </b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items with ms auto not ml auto -->
          <b-navbar-nav class="ms-auto">
            <b-nav-item class="nav-item"><router-link to="/">Home</router-link></b-nav-item>
            <b-nav-item-dropdown text="About" right>
              <b-dropdown-item href="cloudflare-pages"><router-link to="/cloudflare-pages">Cloudflare Pages</router-link></b-dropdown-item>
              <b-dropdown-item href="virtualization"> <router-link to="/virtualization">Virtualization</router-link></b-dropdown-item>
              <b-dropdown-item href="online-shop"><router-link to="/online-shop">Online shop</router-link></b-dropdown-item>
              <b-dropdown-item href="server-administration"><router-link to="/server-administration">Server administration</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item><router-link to="/services" class="nav-item">Services</router-link></b-nav-item>
            <b-nav-item><router-link to="/contact" class="nav-item">Contact</router-link></b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-container>
    <b-container id="hosting-container">
      <b-row>
        <b-col sm="12">
          <article>
            <h1 class="text-center" id="hosting-h1">What services does a Simple Hosting offer?</h1>
            <h3 class="hosting-h3">
              Web hosting is the service by which your site and the files that make it up are stored on a server.
              The web hosting service makes your site accessible to all internet users and is addressed to any
              site / online store.
            </h3>
            <h3 class="hosting-h3">
              The server is a high-performance computer, specially designed and created to offer the possibility
              of storing several sites, and able to serve the requests from customers (internet browsers).
              The web hosting service allows legal entities and individuals to own a web site on the global Internet.
            </h3>
            <h3 class="hosting-h3">
              cPanel is a web hosting control panel software developed by cPanel, LLC. It provides a graphical
              interface and automation tools designed to simplify the process of hosting a website to the
              website owner or "end user". Allows administration via a standard web browser using a three-tier structure.
            </h3>
            <h3  class="hosting-h3">
              Unlimited transfer refers to the fact that the traffic to and from your site is not limited and / or counted.
            </h3>
          </article>
        </b-col>

      </b-row>
      <b-row>
        <b-col sm="6">
          <b-list-group>
            <b-list-group-item class="hosting-list-items"><img src="../assets/hostingsimplubifa@3x.png" alt="check mark" width="80" height="80">Cpanel included</b-list-group-item>
            <b-list-group-item class="hosting-list-items"><img src="../assets/hostingsimplubifa@3x.png" alt="check mark" width="80" height="80">Server capacity 50 GB</b-list-group-item>
            <b-list-group-item class="hosting-list-items"><img src="../assets/hostingsimplubifa@3x.png" alt="check mark" width="80" height="80">Unlimited transfer</b-list-group-item>
            <b-list-group-item class="hosting-list-items"><img src="../assets/hostingsimplubifa@3x.png" alt="check mark" width="80" height="80">Number of www pages max 10</b-list-group-item>
            <b-list-group-item class="hosting-list-items"><img src="../assets/hostingsimplubifa@3x.png" alt="check mark" width="80" height="80">Database max 5</b-list-group-item>
            <b-list-group-item class="hosting-list-items"><img src="../assets/hostingsimplubifa@3x.png" alt="check mark" width="80" height="80">Unlimited email accounts</b-list-group-item>

          </b-list-group>
        </b-col>
        <b-col sm="6">
          <b-list-group>
            <b-list-group-item class="hosting-list-items"><img src="../assets/hostingsimplubifa@3x.png" alt="check mark" width="80" height="80">Installer of the most popular CMS Wordpress, Drupal, Joomla and many more.</b-list-group-item>
            <b-list-group-item class="hosting-list-items"><img src="../assets/hostingsimplubifa@3x.png" alt="check mark" width="80" height="80">Daily backup</b-list-group-item>
            <b-list-group-item class="hosting-list-items"><img src="../assets/hostingsimplubifa@3x.png" alt="check mark" width="80" height="80">Free SSL, of course</b-list-group-item>
            <b-list-group-item class="hosting-list-items"><img src="../assets/hostingsimplubifa@3x.png" alt="check mark" width="80" height="80">Technical support for various cpanel settings via phone or whatsapp. Does not include support for sites or their features</b-list-group-item>
            <b-list-group-item class="hosting-list-items"><img src="../assets/hostingsimplubifa@3x.png" alt="check mark" width="80" height="80">Migration from other hosting company. Free. Always</b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <article>
            <h3 class="hosting-h3">If you need a special type of hosting we can set up a server or a virtual private server anywhere
              in the world, and install your website directly on that server. Or we can install on that server a private whm and cpanel
              instance and set up your website so you can control almost every aspect of it.<br> Of course that we will maintain
              the server for you and provide additional support should your require it.<br>
              The price of this kind of service is 50 eur/month and the price of the normal hosting is 15 eur/month.
            </h3>
            <h3 class="hosting-h3">
              Whatever the situation you may find yourself into, we've got you covered. Just <a href="#/contact"> contact</a> us.
            </h3>
          </article>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'hosting_component'
};
</script>

<style scoped>

#nav{
  background-color: #20A975;
}

#oncloud-logo {
  max-width: 325px;
  max-height: 80px;
}

#hosting-container {
  background: url("../assets/hostingsimplubackground@3x.png") no-repeat center center;
  background-size: cover;

}

#hosting-h1 {
  margin-top: 5%;
  font-family: "SegoeUI Bold", sans-serif;
  letter-spacing: 2px;
}

.hosting-h3 {
  margin-top: 5%;
  font-family: "SegoeUI", sans-serif;
  letter-spacing: 2px;
  line-height: 1.6;
  text-indent: 2%;
}

.hosting-list-items{
  font-family: "SegoeUI", sans-serif;
  font-size: 25px;
  list-style: none;
  border: none;
  color: #576e8b;
  padding-bottom: 15px;
}




@media screen and (max-width: 768px) {
  #navbar-toggler {
    border: none;
    outline: none;
    box-shadow: none;
  }

  #oncloud-logo {
    display: none;
  }

  #navbar-brand {
    background: url("../assets/maskGroup70@3x.png");
    background-size: cover;
    background-position: center center;
    width: 90px;
    max-height: 100px;
    height: 70px;
  }
}


</style>
