<template>
  <div class="virtualization">
    <virtualization_component/>
  </div>
</template>
<script>
// @ is an alias to /src
import virtualization_component from '@/components/virtualization_component';
export default {
  name: 'virtualization',
  components: {virtualization_component}
};
</script>
