<template>
  <div class="home">
    <about_component/>
  </div>
</template>

<script>
// @ is an alias to /src
import about_component from '@/components/about_component.vue'

export default {
  name: 'home',
  components: {
    about_component
  }
}
</script>
