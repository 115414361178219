<template>
<div class="services">
    <services_component/>
</div>
</template>

<script>

import services_component from '@/components/services_component.vue'

export default {
  name: 'services',
  components: {
    services_component
  }
};
</script>

<style scoped>

</style>
