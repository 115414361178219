<template>
  <div>
    <b-container fluid id="nav">
      <b-navbar toggleable="lg" id="navbar">
        <b-navbar-brand href="/" id="navbar-brand"><img id="oncloud-logo" alt="oncloud-logo"  src="../assets/logo/Asset 3@150x@3x.png"/></b-navbar-brand>
        <b-navbar-toggle target="nav-collapse" id="navbar-toggler">
          <template #default="{ expanded }">
            <div v-if="expanded" icon="chevron-bar-up"><img  alt=""  src="../assets/group1838.png"/></div>
            <div v-else icon="chevron-bar-down"><img  alt=""  src="../assets/group1838.png"/></div>
          </template>
        </b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items with ms auto not ml auto -->
          <b-navbar-nav class="ms-auto">
            <b-nav-item class="nav-item"><router-link to="/">Home</router-link></b-nav-item>
            <b-nav-item-dropdown text="About" right class="nav-item">
              <b-dropdown-item href="cloudflare-pages" class="nav-item"><router-link to="/cloudflare-pages">Cloudflare Pages</router-link></b-dropdown-item>
              <b-dropdown-item href="virtualization" class="nav-item"> <router-link to="/virtualization">Virtualization</router-link></b-dropdown-item>
              <b-dropdown-item href="hosting" class="nav-item"><router-link to="/hosting">Hosting</router-link></b-dropdown-item>
              <b-dropdown-item href="online-shop" class="nav-item"><router-link to="/online-shop">Online shop</router-link></b-dropdown-item>
              <b-dropdown-item href="server-administration" class="nav-item"><router-link to="/server-administration">Server administration</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item><router-link to="/services" class="nav-item">Services</router-link></b-nav-item>
            <b-nav-item><router-link to="/contact" class="nav-item">Contact</router-link></b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-container>
    <b-container id="about_container">
      <b-row id="desktop-row">
        <b-col sm="6">
          <h2 class="title">Who we are</h2>
          <h4 class="content">We are an Estonian based company founded by two people from Romania who proposed themselves to change a small particle of the internet, for the better. Why do we say ‘a small particle’? Just because we can afford to take a maximum of 100 clients.
            Why only 100?
            Because we want to be able to offer premium services, and our human infrastructure doesnt’t allow us to go above this number without compromising on something.
            If and when we reach this threshold, maybe we will take another small step. We will keep you updated on our blog.</h4>
          <h2 class="title">What are we good at?</h2>
          <h4 class="content">
            We can build and entire web site from scratch no matter how complex it is (NodeJS, VueJs Handlebars).
            We manage our own server infrastructure globally, so we can offer both premium anycast and unicast domain name services.
            We can proffesionaly manage debian based servers.
            We can offer a good quality web hosting, security first.
            We can host and help you with your WordPress site.
            And many more...
            Oh, and we also know how to be transparent in everything that we do, because we understand how abstract this domain can be.
          </h4>
        </b-col>
        <b-col sm="6">
            <img src="../assets/manatcomputer.svg" class="img-fluid" alt="man sitting at computer">
        </b-col>
      </b-row>
      <b-row id="mobile-row" style="display: none">
        <b-col sm="6">
          <img src="../assets/manatcomputer.svg" class="img-fluid" alt="man sitting at computer">
        </b-col>
        <b-col sm="6">
          <h2 class="title">Who we are</h2>
          <h4 class="content">We are an Estonian based company founded by two people from Romania who proposed themselves to change a small particle of the internet, for the better. Why do we say ‘a small particle’? Just because we can afford to take a maximum of 100 clients.
            Why only 100?
            Because we want to be able to offer premium services, and our human infrastructure doesnt’t allow us to go above this number without compromising on something.
            If and when we reach this threshold, maybe we will take another small step. We will keep you updated on our blog.</h4>
          <h2 class="title">What are we good at?</h2>
          <h4 class="content">
            We can build and entire web site from scratch no matter how complex it is (NodeJS, VueJs Handlebars).
            We manage our own server infrastructure globally, so we can offer both premium anycast and unicast domain name services.
            We can proffesionaly manage debian based servers.
            We can offer a good quality web hosting, security first.
            We can host and help you with your WordPress site.
            And many more...
            Oh, and we also know how to be transparent in everything that we do, because we understand how abstract this domain can be.
          </h4>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'about_component'
};
</script>

<style scoped>

#nav{
  background-color: #20A975;
}

#oncloud-logo {
  max-width: 325px;
  max-height: 80px;
}

.nav-item {
  font-family: "SegoeUI", sans-serif;
}

.title {
  font-family: "SegoeUI Bold", sans-serif;
  letter-spacing: 3px;
  color: #3F6BAD;
  margin-top: 5%;
  margin-bottom: 5%;
}

.content{
  font-family: "SegoeUI", sans-serif;
  letter-spacing: 2px;
  line-height: 1.8;
}

@media screen and (max-width: 768px) {
  #desktop-row {
    display: none;
  }
  #mobile-row {
    display: block!important;
  }
  #navbar-toggler {
    border: none;
    outline: none;
    box-shadow: none;
  }

  #oncloud-logo {
    display: none;
  }

  #navbar-brand {
    background: url("../assets/maskGroup70@3x.png");
    background-size: cover;
    background-position: center center;
    width: 90px;
    max-height: 100px;
    height: 70px;
  }
}

</style>
