<template>
  <div class="online-shop">
    <b-container fluid id="nav">
      <b-navbar toggleable="lg" id="navbar">
        <b-navbar-brand href="/" id="navbar-brand"><img id="oncloud-logo" alt="oncloud-logo"  src="../assets/logo/Asset 3@150x@3x.png"/></b-navbar-brand>
        <b-navbar-toggle target="nav-collapse" id="navbar-toggler">
          <template #default="{ expanded }">
            <div v-if="expanded" icon="chevron-bar-up"><img  alt=""  src="../assets/group1838.png"/></div>
            <div v-else icon="chevron-bar-down"><img  alt=""  src="../assets/group1838.png"/></div>
          </template>
        </b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items with ms auto not ml auto -->
          <b-navbar-nav class="ms-auto">
            <b-nav-item class="nav-item"><router-link to="/">Home</router-link></b-nav-item>
            <b-nav-item-dropdown text="About" right>
              <b-dropdown-item href="cloudflare-pages"><router-link to="/cloudflare-pages">Cloudflare Pages</router-link></b-dropdown-item>
              <b-dropdown-item href="virtualization"> <router-link to="/virtualization">Virtualization</router-link></b-dropdown-item>
              <b-dropdown-item href="hosting"><router-link to="/hosting">Hosting</router-link></b-dropdown-item>
              <b-dropdown-item href="server-administration"><router-link to="/server-administration">Server administration</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item><router-link to="/services" class="nav-item">Services</router-link></b-nav-item>
            <b-nav-item><router-link to="/contact" class="nav-item">Contact</router-link></b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-container>
    <b-container fluid>
      <b-row>
        <b-col sm="12">
           <h1 class="text-center title">Hosting and creating online stores on Magento and Woocommerce platforms.</h1>
           <h2 class="text-center subtitle">If you are wondering what it means to start an online store,
                     here are the steps and details you need to know if you want one for your business.</h2>
        </b-col>
      </b-row>
      <b-container id="content-shop-ctx">
        <b-row>
          <b-col sm="2">
            <img class="img-fluid magazin-img-icon" src="../assets/magazin/calendar@3x.png" alt="calendar">
          </b-col>
          <b-col sm="8">
            <p class="shop-p">
              If you have purchased the internet domain, the pictures of the products, their description and the final cost of their sale,
              in a maximum of 7 days you can have the online store active. If you do not have any of the above, please contact us and we will guide you through the process.
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="2">
            <img class="img-fluid magazin-img-icon" src="../assets/magazin/design@3x.png" alt="pencil">
          </b-col>
          <b-col sm="8">
            <p class="shop-p">
              Its design will be predetermined by the theme of the platform you will choose. We do not deal with custom web design, but we assure you that it will be a pleasant one.
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="2">
            <img class="img-fluid magazin-img-icon" src="../assets/magazin/cost@3x.png" alt="pencil">
          </b-col>
          <b-col sm="8">
            <p class="shop-p">
              The cost of creating the store is 0, and the monthly cost of hosting and maintenance is 50 € / month (invoice in € without VAT) for Woocommerce and 100 € / month for Magento (invoice in euro without VAT).
              </p>
          </b-col>
        </b-row>
        <b-row id="diff-btw-mag-woo-row">
          <b-col sm="12">
         <h2 class="text-center subtitle-big title-virtualization" style="color: rgb(87, 110, 139)"> What are the differences between Magento and Woocommerce?</h2>
          </b-col>
        </b-row>

        <b-row id="diff-btw-mag-woo-content-row">
          <b-col sm="3" md="1" lg="2"></b-col>
          <b-col sm="6" md="10" lg="8">
            <p class="shop-p">While Magento strives to be a complete solution for advanced users,
              WooCommerce strives to be fast, simple, yet reliable, addressing
              small businesses. They can start with something simple and then expand it according to their needs in the future.
            </p>
            <p class="shop-p">
              For more details you can access this <a href="https://svcministry.org/ro/dictionary/what-are-the-major-differences-between-woocommerce-and-magento/" target="_blank">link</a>
              or this <a href="https://beeblebrox.org/articole-i-multe-altele/woocommerce-vs-magento-care-este-cel-mai-bun/" target="_blank">one.</a>
            </p>
            <p class="shop-p">
              Depending on how complex your products are, we will be able to help you make the final decision between the two platforms.
            </p>
          </b-col>
          <b-col sm="3" md="1" lg="2"></b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'online_shop_component'
};
</script>

<style scoped>

#nav{
  background-color: #20A975;
}

#oncloud-logo {
  max-width: 325px;
  max-height: 80px;
}



#content-shop-ctx {
  margin-top: 5%;
}

.title {
  margin-top: 3%;
  font-family: "SegoeUI Bold", sans-serif;
  color: #5ad6a6;
  word-spacing: 15pt;
  font-size: 50pt;
  line-height: 68pt;
}
.subtitle {
  font-family: "SegoeUI", sans-serif;
  font-size: 20pt;
  line-height: 27pt;
}
.subtitle-big {
  margin-top: 2%;
  font-family: "SegoeUI", sans-serif;
  font-size: 40pt;
  word-spacing: 10px;
  line-height: 53pt;
}
.shop-p {
  font-family: "SegoeUI", sans-serif;
  font-size: 20pt;
  line-height: 27pt;
  letter-spacing: 1.88pt;
  color: #576e8b;
  text-indent: 15pt;
}


@media screen and (max-width: 768px) {
  #navbar-toggler {
    border: none;
    outline: none;
    box-shadow: none;
  }

  #oncloud-logo {
    display: none;
  }

  #navbar-brand {
    background: url("../assets/maskGroup70@3x.png");
    background-size: cover;
    background-position: center center;
    width: 90px;
    max-height: 100px;
    height: 70px;
  }
}

@media screen and (max-width: 428px){
  .title {
    font-size: 20pt;
    line-height: 28pt;
    margin-top: 15%;
  }
  .magazin-img-icon {
    width: 80px;
    max-width: 90px;
  }
  .shop-p {
    font-family: "SegoeUI", sans-serif;
    font-size: 17pt;
    line-height: 20pt;
    letter-spacing: 1.88pt;
    color: #576e8b;
  }
  .subtitle-big {
    margin-top: 2%;
    font-family: "SegoeUI", sans-serif;
    font-size: 20pt;
    word-spacing: 10px;
    line-height: 53pt;
  }
}

</style>
