<template>
  <div class="privacy-policy">
    <privacy_policy_component/>
  </div>
</template>

<script>
// @ is an alias to /src
import privacy_policy_component from '@/components/privacy_policy_component.vue'

export default {
  name: 'home',
  components: {
    privacy_policy_component
  }
}
</script>
