<template>
  <div class="cookies">
    <cookies_component/>
  </div>
</template>

<script>
// @ is an alias to /src
import cookies_component from '@/components/cookies_component.vue'

export default {
  name: 'home',
  components: {
    cookies_component
  }
}
</script>
