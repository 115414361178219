<template>
  <div class="cloudflare-pages">
    <cloudflare_pages_component/>
  </div>
</template>

<script>
// @ is an alias to /src
import cloudflare_pages_component from '@/components/cloudflare_pages_component.vue'

export default {
  name: 'cloudflare-pages',
  components: {
    cloudflare_pages_component
  }
}
</script>
