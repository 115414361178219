<template>
  <div class="server_administration">
    <server_administration_component/>
  </div>
</template>

<script>
// @ is an alias to /src
import server_administration_component from '@/components/server_administration_component.vue'

export default {
  name: 'server_administration',
  components: {
    server_administration_component
  }
}
</script>
