<template>
  <div class="server_administration">
    <b-container fluid id="nav">
      <b-navbar toggleable="lg" id="navbar">
        <b-navbar-brand href="/" id="navbar-brand"><img id="oncloud-logo" alt="oncloud-logo"  src="../assets/logo/Asset 3@150x@3x.png"/></b-navbar-brand>
        <b-navbar-toggle target="nav-collapse" id="navbar-toggler">
          <template #default="{ expanded }">
            <div v-if="expanded" icon="chevron-bar-up"><img  alt=""  src="../assets/group1838.png"/></div>
            <div v-else icon="chevron-bar-down"><img  alt=""  src="../assets/group1838.png"/></div>
          </template>
        </b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items with ms auto not ml auto -->
          <b-navbar-nav class="ms-auto">
            <b-nav-item class="nav-item"><router-link to="/">Home</router-link></b-nav-item>
            <b-nav-item-dropdown text="About" right>
              <b-dropdown-item href="cloudflare-pages"><router-link to="/cloudflare-pages">Cloudflare Pages</router-link></b-dropdown-item>
              <b-dropdown-item href="virtualization"> <router-link to="/virtualization">Virtualization</router-link></b-dropdown-item>
              <b-dropdown-item href="hosting"><router-link to="/hosting">Hosting</router-link></b-dropdown-item>
              <b-dropdown-item href="online-shop"><router-link to="/online-shop">Online shop</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item><router-link to="/services" class="nav-item">Services</router-link></b-nav-item>
            <b-nav-item><router-link to="/contact" class="nav-item">Contact</router-link></b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-container>
    <b-container fluid id="server-admin-background">
      <b-container id="content-ctx">
        <b-row>
          <b-col sm="12">
            <h1 class="text-center title">
              What does managing a server mean?
            </h1>
            <h2 class="text-center subtitle">
              Steps we'll take to manage your business server
            </h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="1">
            <img alt="first text" src="../assets/serveradmin/unu@3x.png" width="150" height="150" class="img-fluid numbers">
          </b-col>
          <b-col lg="5" id="col-1">
            <p class="admin-p">
              First of all, if you don't already have a server, we offer specialized consultancy for the acquisition of one with the optimal configuration for the IT structure of
              your organization. The server can be local or in the cloud - specialized data center.
              Server performance directly influences a company's core business.
            </p>
          </b-col>
          <b-col lg="1">
            <img alt="second text" src="../assets/serveradmin/doi@3x.png" width="150" height="150" class="img-fluid numbers">
          </b-col>
          <b-col lg="5">
            <p class="admin-p">
              Then we install the applications you need: management / accounting software, file server (a common place where your colleagues can store files where
              everyone to have access), automatic back ups, etc.
              The server, if it is local, can also be the interface through which the staff communicates with the Internet, offering at the same time an internal and secure private network.
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="1">
            <img alt="third text" src="../assets/serveradmin/trei@3x.png" width="150" height="150" class="img-fluid numbers">
          </b-col>
          <b-col lg="5" id="3">
            <p class="admin-p">
              All these need maintenance, updates, reinstallation, etc., operations that are our responsibility.
            </p>
          </b-col>
          <b-col lg="1">
            <img alt="fourth text" src="../assets/serveradmin/patru@3x.png" width="150" height="150" class="img-fluid numbers">
          </b-col>
          <b-col lg="5">
            <p class="admin-p">
              The above, as well as the rest of the details, are decided following a discussion held at the client's headquarters or online, an extremely important discussion, which, among other things, must contain the expansion plans for the next 5 years.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'server_administration_component'
};
</script>

<style scoped>

#nav{
  background-color: #20A975;
}

#oncloud-logo {
  max-width: 325px;
  max-height: 80px;
}

@media screen and (max-width: 768px) {
  #navbar-toggler {
    border: none;
    outline: none;
    box-shadow: none;
  }

  #oncloud-logo {
    display: none;
  }

  #navbar-brand {
    background: url("../assets/maskGroup70@3x.png");
    background-size: cover;
    background-position: center center;
    width: 90px;
    max-height: 100px;
    height: 70px;
  }
}

#server-admin-background {
  background-color: #eceff7;
}

.title {
  margin-top: 3%;
  font-family: "SegoeUI Bold", sans-serif;
  color: #5ad6a6;
  word-spacing: 15pt;
  font-size: 50pt;
  line-height: 68pt;
}
.subtitle {
  margin-top: 2%;
  margin-bottom: 5%;
  font-family: "SegoeUI", sans-serif;
  font-size: 40pt;
  word-spacing: 10px;
  line-height: 53pt;
}

#col-1 {
  margin-bottom: 5%;
}
#col-3 {
  margin-bottom: 5%;
}
.admin-p {
  font-family: "SegoeUI", sans-serif;
  font-size: 17pt;
  letter-spacing: 1.88pt;
  line-height: 27pt;
  text-align: justify;
}
@media screen and (max-width: 428px){
  .title {
    font-size: 25pt;
    line-height: 28pt;
    margin-top: 15%;
  }
  .subtitle {
    margin-top: 2%;
    margin-bottom: 5%;
    font-family: "SegoeUI", sans-serif;
    font-size: 19pt;
    word-spacing: 10px;
    line-height: 20pt;
  }
}
</style>
