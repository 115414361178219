<template>
  <div class="virtualization">
    <b-container fluid id="nav">
      <b-navbar toggleable="lg" id="navbar">
        <b-navbar-brand href="/" id="navbar-brand"><img id="oncloud-logo" alt="oncloud-logo"  src="../assets/logo/Asset 3@150x@3x.png"/></b-navbar-brand>
        <b-navbar-toggle target="nav-collapse" id="navbar-toggler">
          <template #default="{ expanded }">
            <div v-if="expanded" icon="chevron-bar-up"><img  alt=""  src="../assets/group1838.png"/></div>
            <div v-else icon="chevron-bar-down"><img  alt=""  src="../assets/group1838.png"/></div>
          </template>
        </b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items with ms auto not ml auto -->
          <b-navbar-nav class="ms-auto">
            <b-nav-item class="nav-item"><router-link to="/">Home</router-link></b-nav-item>
            <b-nav-item-dropdown text="About" right>
              <b-dropdown-item href="cloudflare-pages"><router-link to="/cloudflare-pages">Cloudflare Pages</router-link></b-dropdown-item>
              <b-dropdown-item href="hosting"><router-link to="/hosting">Hosting</router-link></b-dropdown-item>
              <b-dropdown-item href="online-shop"><router-link to="/online-shop">Online shop</router-link></b-dropdown-item>
              <b-dropdown-item href="server-administration"><router-link to="/server-administration">Server administration</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item><router-link to="/services" class="nav-item">Services</router-link></b-nav-item>
            <b-nav-item><router-link to="/contact" class="nav-item">Contact</router-link></b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-container>
    <b-container fluid id="virtualization-main-container">
      <b-row>
        <b-col sm="12">
        <h1 class="text-center title-h1">What are the benefits of choosing virtualization?</h1>
        </b-col>
      </b-row>
      <b-container>
        <b-row style="padding-bottom: 5%;padding-top: 5%" id="desktop-benefits-of-virtualization">
        <b-col sm="6" lg="6" md="12">
          <b-card class="virtual-card">
            <img alt="security" src="../assets/virtulizare/securitybadge@3x.png" class="img-fluid" width="120" height="152">
            <b-card-body>
              <h5 class="card-title" id="security-h5">Security</h5>
              <p class="card-text">
                What this means? The power of virtualization lies in the fact that we can make daily backups on all PCs of the company, and if today anything happens to any of them, or all at once, we always have yesterday's available.
                We delete today's problematic ones (ransomware for example) and release yesterday's ones. We only lose the hours worked today, not all the data.
                We can go back in time how many days we decide to keep backups, and the limitations consist only in the storage space of the computer-server.
              </p>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col sm="6" lg="6" md="12">
          <b-card class="virtual-card">
            <img alt="costs" src="../assets/virtulizare/expensesbadge@3x.png" class="img-fluid" width="120" height="152">
            <b-card-body>
              <h5 class="card-title" id="costs-h5">Costs</h5>
              <p class="card-text">
                Another major benefit worth mentioning is the cost of the physical part (hardware) of the IT infrastructure.
                If we have correctly planned the resources of the computer-server and we need more power to one of the computers, we simply upgrade
                in a few clicks. You will never need to buy new PCs. In the worst case, in a few years, we will have to make changes to the computer-server.
              </p>
            </b-card-body>
          </b-card>
        </b-col>
          <b-row>
            <h1 class="text-center title-h1 title-virtualization">What is virtualization?</h1>
            <h1 class="text-center subtitle subtitle-virtualization">Oncloud explains the virtualization process in detail.</h1>
            <p class="card-text">Hello Steph!?</p>
          </b-row>
        </b-row>

        <b-row id="mobile-benefits-of-virtualization" style="display: none">
          <b-carousel
              id="carousel-1"
              v-model="slide"
              :interval="14000"
              indicators
              style="text-shadow: 1px 1px 2px #333;"
              @sliding-start="onSlideStart"
              @sliding-end="onSlideEnd"
          >
            <b-carousel-slide class="b-carousel-slide">
              <h3>Security</h3>
              <template #img>
                <img
                    class="img-fluid custom-class-image"
                    width="400"
                    height="400"
                    src="../assets/virtulizare/securitybadge@3x.png"
                    alt="security"
                >
              </template>
              <p>What this means? The power of virtualization lies in the fact that we can make daily backups on all PCs of the company, and if today anything happens to any of them, or all at once, we always have yesterday's available.
                We delete today's problematic ones (ransomware for example) and release yesterday's ones. We only lose the hours worked today, not all the data.
                We can go back in time how many days we decide to keep backups, and the limitations consist only in the storage space of the computer-server.</p>
            </b-carousel-slide>
            <b-carousel-slide class="b-carousel-slide">
              <h3>Costs</h3>
              <template #img>
                <img
                    class="img-fluid custom-class-image"
                    width="400"
                    height="400"
                    src="../assets/virtulizare/expensesbadge@3x.png"
                    alt="expenses"
                >
              </template>

              <p>Another major benefit worth mentioning is the cost of the physical part (hardware) of the IT infrastructure.
                If we have correctly planned the resources of the computer-server and we need more power to one of the computers, we simply upgrade
                in a few clicks. You will never need to buy new PCs. In the worst case, in a few years, we will have to make changes to the computer-server</p>

            </b-carousel-slide>
          </b-carousel>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'virtualization_component',
  components: {}

};
</script>

<style scoped>

#nav{
  background-color: #20A975;
}

#oncloud-logo {
  max-width: 325px;
  max-height: 80px;
}

#virtualization-main-container{
  background-color: #eceff7;
  height: auto;
}
.title-h1{
  margin-top: 3%;
  font-family: "SegoeUI Bold", sans-serif;
  color: #5ad6a6;
  word-spacing: 15pt;
  font-size: 50pt;
  line-height: 68pt;
}
.subtitle {
  margin-top: 2%;
  font-family: "SegoeUI", sans-serif;
  font-size: 40pt;
  word-spacing: 10px;
  line-height: 53pt;
}
.virtual-card {
  border: none !important;
  border-radius: 30px !important;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  background-color: transparent;
  height: 95% !important;
}
#security-h5{
  font-family: "SegoeUI Bold", sans-serif;
  font-size: 33pt;
  line-height: 45pt;
  padding-top: 10px;
  color: #5ad6a6;
}
#costs-h5 {
  font-family: "SegoeUI Bold", sans-serif;
  font-size: 33pt;
  line-height: 45pt;
  color: #0234ff;
  padding-top: 10px;
}
.card-text {
  font-family: "SegoeUI", sans-serif;
  font-size: 15pt;
  line-height: 30pt;
  color: #576e8b;
  padding-bottom: 50pt;
  padding-left: 20pt;
  padding-right: 20pt;
}


@media screen and (max-width: 768px) {
  #navbar-toggler {
    border: none;
    outline: none;
    box-shadow: none;
  }

  #oncloud-logo {
    display: none;
  }

  #navbar-brand {
    background: url("../assets/maskGroup70@3x.png");
    background-size: cover;
    background-position: center center;
    width: 90px;
    max-height: 100px;
    height: 70px;
  }
}
@media screen and (max-width: 428px){
  .title-h1{
    margin-top: 5%;
    font-family: "SegoeUI Bold", sans-serif;
    color: #5ad6a6;
    word-spacing: 15pt;
    font-size: 20pt;
    line-height: 28pt;
  }

  #desktop-benefits-of-virtualization {
    display: none;
  }

  #mobile-benefits-of-virtualization {
    display: block !important;
    height: 100vh;
  }

  .b-carousel-slide {
    height: 80vh;
    text-align: center!important;
    margin-top: 5px;
    font-size: 20px;
    left: 0!important;
  }


}

</style>
