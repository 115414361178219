<template>
  <b-container fluid id="nav">
    <b-navbar toggleable="lg" id="navbar">
      <b-navbar-brand href="/" id="navbar-brand"><img id="oncloud-logo" alt="oncloud-logo"  src="../assets/logo/Asset 3@150x@3x.png"/></b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" id="navbar-toggler">
        <template #default="{ expanded }">
          <div v-if="expanded" icon="chevron-bar-up"><img  alt=""  src="/src/assets/group1838.png"/></div>
          <div v-else icon="chevron-bar-down"><img  alt=""  src="/src/assets/group1838.png"/></div>
        </template>
      </b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items with ms auto not ml auto -->
        <b-navbar-nav class="ms-auto">
          <b-nav-item><router-link to="/">Home</router-link></b-nav-item>
          <b-nav-item-dropdown text="About" right>
            <b-dropdown-item href="cloudflare-pages"><router-link to="/cloudflare-pages">Cloudflare Pages</router-link></b-dropdown-item>
            <b-dropdown-item href="virtualization"> <router-link to="/virtualization">Virtualization</router-link></b-dropdown-item>
            <b-dropdown-item href="hosting"><router-link to="/hosting">Hosting</router-link></b-dropdown-item>
            <b-dropdown-item href="online-shop"><router-link to="/online-shop">Online shop</router-link></b-dropdown-item>
            <b-dropdown-item href="server-administration"><router-link to="/server-administration">Server administration</router-link></b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item><router-link to="/services">Services</router-link></b-nav-item>
          <b-nav-item><router-link to="/contact">Contact</router-link></b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </b-container>
</template>

<script>
export default {
  name: 'cookies_component'
};
</script>

<style scoped>

</style>
