<template>
<div class="services">
  <b-container fluid id="nav">
    <b-navbar toggleable="lg" id="navbar">
      <b-navbar-brand href="/" id="navbar-brand"><img id="oncloud-logo" alt="oncloud-logo"  src="../assets/logo/Asset 3@150x@3x.png"/></b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" id="navbar-toggler">
        <template #default="{ expanded }">
          <div v-if="expanded" icon="chevron-bar-up"><img  alt=""  src="../assets/group1838.png"/></div>
          <div v-else icon="chevron-bar-down"><img  alt=""  src="../assets/group1838.png"/></div>
        </template>
      </b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items with ms auto not ml auto -->
        <b-navbar-nav class="ms-auto">
          <b-nav-item class="nav-item"><router-link to="/">Home</router-link></b-nav-item>
          <b-nav-item-dropdown text="About" right class="nav-item">
            <b-dropdown-item href="cloudflare-pages" class="nav-item"><router-link to="/cloudflare-pages">Cloudflare Pages</router-link></b-dropdown-item>
            <b-dropdown-item href="virtualization" class="nav-item"> <router-link to="/virtualization">Virtualization</router-link></b-dropdown-item>
            <b-dropdown-item href="hosting" class="nav-item"><router-link to="/hosting">Hosting</router-link></b-dropdown-item>
            <b-dropdown-item href="online-shop" class="nav-item"><router-link to="/online-shop">Online shop</router-link></b-dropdown-item>
            <b-dropdown-item href="server-administration" class="nav-item"><router-link to="/server-administration">Server administration</router-link></b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item><router-link to="/services" class="nav-item">Services</router-link></b-nav-item>
          <b-nav-item><router-link to="/contact" class="nav-item">Contact</router-link></b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </b-container>
</div>
</template>

<script>
export default {
  name: 'services_component'
};
</script>

<style scoped>
#nav{
  background-color: #20A975;
}

#oncloud-logo {
  max-width: 325px;
  max-height: 80px;
}

@media screen and (max-width: 768px) {
  #navbar-toggler {
    border: none;
    outline: none;
    box-shadow: none;
  }

  #oncloud-logo {
    display: none;
  }

  #navbar-brand {
    background: url("../assets/maskGroup70@3x.png");
    background-size: cover;
    background-position: center center;
    width: 90px;
    max-height: 100px;
    height: 70px;
  }
}
</style>
